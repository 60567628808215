import domReady from '@wordpress/dom-ready';
import { Fancybox } from '@fancyapps/ui';

domReady(() => {
  const galleryItems = document.querySelectorAll(
    '.images-gallery-block__grid__item__link'
  );

  if (0 < galleryItems.length) {
    Fancybox.bind('.images-gallery-block__grid__item__link', {});
  }
});
